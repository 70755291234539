exports.components = {
  "component---src-components-search-search-js": () => import("./../../../src/components/search/search.js" /* webpackChunkName: "component---src-components-search-search-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-knp-about-knp-js": () => import("./../../../src/templates/about-knp/about-knp.js" /* webpackChunkName: "component---src-templates-about-knp-about-knp-js" */),
  "component---src-templates-blog-category-blog-category-js": () => import("./../../../src/templates/blog-category/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-blog-category-js" */),
  "component---src-templates-blog-homepage-blog-homepage-js": () => import("./../../../src/templates/blog-homepage/blog-homepage.js" /* webpackChunkName: "component---src-templates-blog-homepage-blog-homepage-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-contact-js": () => import("./../../../src/templates/contact/contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-job-details-job-details-js": () => import("./../../../src/templates/job-details/job-details.js" /* webpackChunkName: "component---src-templates-job-details-job-details-js" */),
  "component---src-templates-jobs-jobs-js": () => import("./../../../src/templates/jobs/jobs.js" /* webpackChunkName: "component---src-templates-jobs-jobs-js" */),
  "component---src-templates-landing-page-landing-page-js": () => import("./../../../src/templates/landing-page/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-landing-page-js" */),
  "component---src-templates-portfolio-portfolio-js": () => import("./../../../src/templates/portfolio/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-portfolio-js" */),
  "component---src-templates-services-services-js": () => import("./../../../src/templates/services/services.js" /* webpackChunkName: "component---src-templates-services-services-js" */),
  "component---src-templates-terms-and-conditions-terms-and-conditions-js": () => import("./../../../src/templates/terms-and-conditions/terms-and-conditions.js" /* webpackChunkName: "component---src-templates-terms-and-conditions-terms-and-conditions-js" */),
  "component---src-templates-training-details-training-details-js": () => import("./../../../src/templates/training-details/training-details.js" /* webpackChunkName: "component---src-templates-training-details-training-details-js" */),
  "component---src-templates-trainings-trainings-js": () => import("./../../../src/templates/trainings/trainings.js" /* webpackChunkName: "component---src-templates-trainings-trainings-js" */)
}

